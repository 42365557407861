import React from 'react'
import styled from 'styled-components'

import { screen } from '../breakpoints'
import ArrowRightIcon from '../icons/arrow-right'
import ArrowLeftIcon from '../icons/arrow-left'

const Wrapper = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
`

const Button = styled.div`
  padding: 10px;
  cursor: pointer;
  color: var(--subtle);
  user-select: none;

  svg {
    display: block;
    transition: transform 250ms ease;
  }
`

// inherit common styles from Button and add more
const PreviousSlideButton = styled(Button)`
  margin-right: 5px;

  :hover svg {
    transform: translateX(-5px);
  }
`

// inherit common styles from Button and add more
const NextSlideButton = styled(Button)`
  margin-left: 5px;

  :hover svg {
    transform: translateX(5px);
  }
`

const SlideIndicator = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0 2px;
  cursor: pointer;
  user-select: none;

  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 10px;
    height: 10px;
    border-radius: 2px;
    opacity: 0.5;
    background-color: var(--subtle);
    transition: opacity 150ms ease;
    opacity: ${(props) => (props.active ? 1 : 0.5)};
  }
`

const IndicatorGroup = styled.div`
  color: var(--subtle);
  display: none;

  @media ${screen.sm} {
    display: flex;
    flex-direction: row;
  }
`

const FractionGroup = styled.div`
  color: var(--subtle);
  margin: 0 20px;

  @media ${screen.sm} {
    display: none;
  }
`

export default function Navigation(props) {
  // Create an array with length of slides to map over later
  const slideIndicators = new Array(props.slides).fill(0)

  return (
    <Wrapper>
      <PreviousSlideButton
        role="button"
        aria-label="Previous slide"
        className="slideshow-previous-slide"
        onClick={props.onPrevious}
      >
        <ArrowLeftIcon css="height: 15px;" />
      </PreviousSlideButton>
      <IndicatorGroup>
        {slideIndicators.map((_, slideIndex) => (
          <SlideIndicator
            role="button"
            aria-label={`Slide ${slideIndex + 1}`}
            key={slideIndex}
            active={props.activeSlide === slideIndex}
            onClick={() => props.onSeek(slideIndex)}
          />
        ))}
      </IndicatorGroup>
      <FractionGroup>
        {props.activeSlide + 1} / {props.slides}
      </FractionGroup>
      <NextSlideButton
        role="button"
        aria-label="Next slide"
        className="slideshow-next-slide"
        onClick={props.onNext}
      >
        <ArrowRightIcon css="height: 15px;" />
      </NextSlideButton>
    </Wrapper>
  )
}
