import React from 'react'
import { Helmet } from 'react-helmet'

export default function LdJson(props) {
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(props.children || props.schema)}
      </script>
    </Helmet>
  )
}
