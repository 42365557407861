import React, { useState, useMemo } from 'react'
import { Helmet } from 'react-helmet'

import styled from 'styled-components'
import { graphql, withPrefix, Link } from 'gatsby'

import Page, { Section } from '../shared/page-commons'
import Slideshow from '../shared/slideshow'
import LdJson from '../shared/ld+json'

import {
  Grid,
  Card,
  CardActionCall,
  CardTextGroup,
  MoreCard,
} from '../shared/card-grid'
import { absoluteSize, centerColumn } from '../shared/common-styles'
import { screen } from '../shared/breakpoints'
import ResearchArea, { RESEARCH_LOOKUP } from '../shared/research-area'
import SectionHeader from '../shared/section-header'
import ColorContext from '../shared/color-context'
import FilterData from '../shared/filter'
import Tag from '../shared/tag'
import * as defaults from '../shared/markdown-defaults'
import NotificationBanner from '../shared/notification-banner'

const BannerWrapper = styled.section`
  position: relative;
  padding: 80px 0;

  @media ${screen.md} {
    padding: 130px 0;
  }
`

const BackgroundImage = styled.div`
  ${absoluteSize};
  background-color: var(--accent-bright);
  background-image: url(${withPrefix('/images/core/homepage_big_banner.jpg')});
  background-blend-mode: luminosity;
  background-size: cover;
  background-position: center;
  filter: grayscale(1);
`

const OverlayColor = styled.div`
  ${absoluteSize};
  background-color: var(--accent-bright);
  opacity: 0.5;

  @supports (mix-blend-mode: overlay) {
    mix-blend-mode: overlay;
    opacity: 1;
  }
`

const OverlayDarken = styled.div`
  ${absoluteSize};
  background-color: black;
  opacity: 0.55;
`

const BannerTextGroup = styled.div`
  ${centerColumn};
  text-align: left;
  font-weight: bold;
  color: var(--gray-9);
  font-family: var(--font-heading);
  line-height: 1.3;
  position: relative;

  h1 {
    font-size: 2.75rem;
    margin: 0;
    margin-bottom: 0.5em;
  }

  p {
    font-size: 1.5rem;
  }

  @media ${screen.sm} {
    h1 {
      font-size: 3rem;
    }
    p {
      font-size: 1.625rem;
    }
  }

  @media ${screen.md} {
    h1 {
      font-size: 3.25rem;
      margin-bottom: 0.25em;
    }
  }
`

function Banner(props) {
  return (
    <BannerWrapper>
      <BackgroundImage />
      <OverlayColor />
      <OverlayDarken />
      <BannerTextGroup>
        <h1>Advanced Concepts Team</h1>
        <p>
          Our mission is to monitor and perform research on advanced space
          concepts and technologies, preparing ESA for any disruptive change to
          come.
        </p>
      </BannerTextGroup>
    </BannerWrapper>
  )
}

const Ratio = styled.div`
  padding-bottom: 61.81%;

  @media ${screen.sm} {
    padding-bottom: 75%;
  }
`

const Image = styled.img`
  ${absoluteSize};
  display: block;
  object-fit: cover;
`

const ResearchAreaLabel = styled(Tag)`
  position: absolute;
  top: -10px;
  left: 20px;
  max-width: calc(100% - 40px);
`

const Title = styled.h3`
  font-size: 1.125rem;
  flex-grow: 1;
  margin-bottom: 30px;
  color: var(--heading);
`

// memoize this component to improve render performance
const ResearchCard = React.memo((props) => {
  const { research, ...restProps } = props

  let researchArea
  if (research.categories && research.categories.length) {
    if (research.categories.length === 1) {
      researchArea = research.categories[0]
    } else {
      researchArea = 'Multidisciplinary'
    }
  }

  return (
    <Card {...restProps}>
      <figure css="position: relative;">
        <Ratio />
        <Image src={withPrefix(research.image_src)} alt={research.title} />
      </figure>
      <CardTextGroup>
        {researchArea && (
          <ResearchAreaLabel>
            <ResearchArea>{researchArea}</ResearchArea>
          </ResearchAreaLabel>
        )}
        <Title>{research.title}</Title>
        <CardActionCall>Read more</CardActionCall>
      </CardTextGroup>
    </Card>
  )
})

function filterResearchItem(activeFilters, item) {
  const { frontmatter } = item

  if (Array.isArray(frontmatter.categories)) {
    for (const category of frontmatter.categories) {
      if (activeFilters.includes(category)) {
        return true
      }
    }
  }

  return false
}

function ResearchOverview(props) {
  const [showingAll, setShowingAll] = useState(false)

  const research = useMemo(() => {
    if (showingAll) return props.research
    // only show ongoing research
    return props.research.filter((entry) => entry.frontmatter.ongoing)
  }, [props.research, showingAll])

  return (
    <Section id="research">
      <SectionHeader>Our research</SectionHeader>
      <FilterData
        data={research}
        filterItem={filterResearchItem}
        filterOptions={RESEARCH_LOOKUP}
        renderResult={(filteredResearch) => {
          return (
            <Grid css="margin-top: 30px;">
              {filteredResearch.map(({ frontmatter, fields }) => (
                <ResearchCard
                  key={frontmatter.title}
                  research={frontmatter}
                  document={frontmatter.document_url}
                  href={frontmatter.external_url}
                  to={frontmatter.internal_url || fields.slug}
                />
              ))}
              {!showingAll && (
                <MoreCard onClick={() => setShowingAll(true)}>
                  Show concluded research
                </MoreCard>
              )}
            </Grid>
          )
        }}
      />
    </Section>
  )
}

function prepareSlideData(news) {
  const { frontmatter, fields, id } = news

  return {
    key: id,
    src: frontmatter.image_src,
    alt: frontmatter.title,
    title: frontmatter.title,
    description: frontmatter.headline,
    document: frontmatter.document_url,
    href: frontmatter.external_url,
    to: frontmatter.internal_url || fields.slug,
  }
}

export default function HomePage(props) {
  const newsSlides = props.data.news.nodes.map(prepareSlideData)
  const { site } = props.data

  return (
    <Page>
      <Helmet>
        <meta
          name="description"
          content="The Advanced Concepts Team is a group of scientists, researchers and young graduates that perform multidisciplinary research within the European Space Agency."
        />
        <meta
          property="og:description"
          content="The Advanced Concepts Team is a group of scientists, researchers and young graduates that perform multidisciplinary research within the European Space Agency."
        />
        <meta
          name="twitter:description"
          content="The Advanced Concepts Team is a group of scientists, researchers and young graduates that perform multidisciplinary research within the European Space Agency."
        />
      </Helmet>
      <LdJson>
        {{
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: 'Advanced Concepts Team',
          alternateName: 'ACT',
          url: site.siteMetadata.siteUrl + withPrefix(''),
          logo: site.siteMetadata.siteUrl + withPrefix(site.siteMetadata.logo),
          sameAs: [
            'https://www.youtube.com/user/AdvancedConcepts',
            'https://www.linkedin.com/groups/4154938/',
            'https://en.wikipedia.org/wiki/Advanced_Concepts_Team',
            'https://github.com/esa',
          ],
        }}
      </LdJson>
      <ColorContext theme="dark" bgColor="background">
        <Banner />
        {props.data.researchFellowPositions.totalCount > 0 && (
          <NotificationBanner>
            We are currently{' '}
            <defaults.a as={Link} to="/about/research_fellows">
              hiring new Research fellows
            </defaults.a>{' '}
            for:{' '}
            {props.data.researchFellowPositions.nodes
              .map((node) => node.frontmatter.title)
              .join(', ')}
            .
          </NotificationBanner>
        )}
        {props.data.internshipPositions.totalCount > 0 && (
          <NotificationBanner>
            The{' '}
            <defaults.a as={Link} to="/about/interns">
              ACT internships descriptions
            </defaults.a>{' '}
            are available for this year. Apply at ESA's{' '}
            <defaults.a href="https://www.esa.int/About_Us/Careers_at_ESA/Student_Internships2">
              {' '}
              internship programme
            </defaults.a>
            .
          </NotificationBanner>
        )}
        {props.data.ariadnaPositions.totalCount > 0 && (
          <NotificationBanner>
            There are currently new{' '}
            <defaults.a as={Link} to="/collab/ariadna_calls">
              Ariadna Open Calls
            </defaults.a>
            .
          </NotificationBanner>
        )}

        <Section id="news">
          <Slideshow slides={newsSlides} />
        </Section>
      </ColorContext>

      <ResearchOverview research={props.data.research.nodes} />
    </Page>
  )
}

// query the required data (all current members) from the graphql nodes
export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        siteUrl
        logo
      }
    }
    news: allMdx(
      filter: {
        fileAbsolutePath: { glob: "**/collections/news/*" }
        frontmatter: { ongoing: { eq: true } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        frontmatter {
          title
          headline
          image_src
          internal_url
          external_url
          document_url
        }
        fields {
          slug
        }
      }
    }
    research: allMdx(
      filter: { fileAbsolutePath: { glob: "**/collections/projects/*" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          image_src
          categories
          ongoing
          internal_url
          external_url
          document_url
        }
        fields {
          slug
        }
      }
    }
    researchFellowPositions: allMdx(
      filter: {
        fileAbsolutePath: { glob: "**/collections/research_fellowships/*" }
        frontmatter: { ongoing: { eq: true } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      totalCount
      nodes {
        frontmatter {
          title
        }
        fields {
          slug
        }
      }
    }
    internshipPositions: allMdx(
      filter: {
        fileAbsolutePath: { glob: "**/collections/internships/*" }
        frontmatter: { ongoing: { eq: true } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      totalCount
    }
    ariadnaPositions: allMdx(
      filter: {
        fileAbsolutePath: { glob: "**/collections/ariadna/*" }
        frontmatter: { ongoing: { eq: true } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      totalCount
    }
  }
`
