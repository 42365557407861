import React from 'react'

export default function ArrowLeftIcon(props) {
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 11.4"
      fill="currentColor"
      {...props}
    >
      <g transform="translate(19380 17820)">
        <path
          d="M-19373.9-17808.7l0.3-0.3c0.2-0.2,0.2-0.5,0-0.8l-3.7-3.7h16.8c0.3,0,0.5-0.2,0.5-0.5v-0.4
                            		c0-0.3-0.2-0.5-0.5-0.5h-16.8l3.7-3.7c0.2-0.2,0.2-0.5,0-0.8l-0.3-0.3c-0.2-0.2-0.5-0.2-0.8,0l-5.2,5.2c-0.2,0.2-0.2,0.5,0,0.8
                            		l5.2,5.2C-19374.4-17808.5-19374.1-17808.5-19373.9-17808.7C-19373.9-17808.7-19373.9-17808.7-19373.9-17808.7z"
        />
      </g>
    </svg>
  )
}
