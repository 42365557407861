import React, { useState, useEffect, useCallback } from 'react'
import { useTransition } from 'react-spring'
import styled from 'styled-components'

import { screen } from '../breakpoints'
import { makeSpringConfig } from '../spring'
import Navigation from './navigation'
import Slide, { SLIDE_TEXT_HEIGHT } from './slide'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'

const TIME_PER_SLIDE = 6000

const ItemsGroup = styled.div`
  position: relative;
  height: calc(${SLIDE_TEXT_HEIGHT}px + 0.6181 * (100vw - 40px));
  max-width: 450px;
  margin: 0 auto;
  background-color: var(--surface);
  border-radius: 2px;
  box-shadow: 0 0.6px 1px rgba(0, 0, 0, 0.017),
    0 1.5px 2.3px rgba(0, 0, 0, 0.024), 0 2.9px 4.4px rgba(0, 0, 0, 0.03),
    0 5.1px 7.8px rgba(0, 0, 0, 0.036), 0 9.6px 14.6px rgba(0, 0, 0, 0.043),
    0 23px 35px rgba(0, 0, 0, 0.06);

  @media (min-width: 490px) {
    height: calc(${SLIDE_TEXT_HEIGHT}px + 0.6181 * 450px);
  }

  @media ${screen.lg} {
    max-width: none;
    height: calc(0.6181 * 0.6667 * var(--column-content-width));
  }
`

const PreloadImages = React.memo((props) => {
  return (
    <Helmet>
      {props.slides.map((slide) => {
        // Preload images in the slideshow to prevent image flicker when changing slides.
        // This was because the image still needed to be downloaded when the new slide was active.
        // Now the browser can preload these images and thereby making the animation appear smoother.
        return (
          <link
            key={slide.key}
            rel="preload"
            href={withPrefix(slide.src)}
            as="image"
          />
        )
      })}
    </Helmet>
  )
})

function pageIsVisible() {
  return document.visibilityState === 'visible'
}

export default function Slideshow(props) {
  const numberOfSlides = props.slides.length
  const [slideIndex, setSlideIndex] = useState(0)

  const transitions = useTransition(slideIndex, null, {
    initial: { position: 'absolute', opacity: 1 },
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0, pointerEvents: 'none' },
    config: makeSpringConfig({ response: 500 }),
  })

  const normalizeSlideIndex = useCallback(
    (index) => {
      let normalizedIndex = index
      // normalize slide index to the range [-numberOfSlides, +numberOfSlides]
      normalizedIndex %= numberOfSlides
      if (normalizedIndex < 0) normalizedIndex += numberOfSlides
      return normalizedIndex
    },
    [numberOfSlides]
  )

  // useCallback memoizes the function this will prevent unnecessary renders.
  const onPrevious = useCallback(() => {
    setSlideIndex((currentSlideIndex) => {
      return normalizeSlideIndex(currentSlideIndex - 1)
    })
  }, [normalizeSlideIndex])

  const onNext = useCallback(() => {
    setSlideIndex((currentSlideIndex) => {
      return normalizeSlideIndex(currentSlideIndex + 1)
    })
  }, [normalizeSlideIndex])

  const onSeek = useCallback(
    (slideIndex) => {
      setSlideIndex(normalizeSlideIndex(slideIndex))
    },
    [normalizeSlideIndex]
  )

  useEffect(() => {
    let timeoutId

    // Prevent react-spring bug: https://github.com/react-spring/react-spring/issues/926
    // by not triggering an animation when the page is not visible
    // TODO: this should be fixed in react-spring version 9
    function handleVisibilityChange() {
      if (pageIsVisible()) {
        timeoutId = setTimeout(onNext, TIME_PER_SLIDE)
      } else {
        clearTimeout(timeoutId)
      }
    }

    if (pageIsVisible()) {
      timeoutId = setTimeout(onNext, TIME_PER_SLIDE)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return function cleanup() {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      clearTimeout(timeoutId)
    }
  }, [onNext, slideIndex])

  return (
    <div>
      <PreloadImages slides={props.slides} />
      <ItemsGroup>
        {transitions.map(({ item, key, props: style }) => {
          return <Slide key={key} {...props.slides[item]} style={style} />
        })}
      </ItemsGroup>
      <Navigation
        slides={numberOfSlides}
        activeSlide={slideIndex}
        onNext={onNext}
        onPrevious={onPrevious}
        onSeek={onSeek}
      />
    </div>
  )
}
