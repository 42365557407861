import React from 'react'
import styled from 'styled-components'
import { centerColumn } from './common-styles'

const StyledNotificationBanner = styled.div`
  margin-top: 4px;
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 10px,
    var(--surface) 10px,
    var(--surface) 20px
  );
`

const Column = styled.div`
  ${centerColumn};
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Text = styled.div`
  color: var(--text);
`

export default function NotificationBanner(props) {
  return (
    <StyledNotificationBanner {...props}>
      <Column>
        <Text>{props.children}</Text>
      </Column>
    </StyledNotificationBanner>
  )
}
