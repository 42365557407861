import React from 'react'
import { animated } from 'react-spring'
import styled from 'styled-components'
import { withPrefix } from 'gatsby'

import Tag from '../tag'
import { screen } from '../breakpoints'
import Link from '../link'

export const SLIDE_TEXT_HEIGHT = 307

const StyledSlide = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const LinkWrapper = styled(Link)`
  @media ${screen.lg} {
    display: flex;
  }
`

const Ratio = styled.div`
  padding-bottom: 61.81%;
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border: 4px solid transparent;
`

const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 25px 25px;
  min-height: ${SLIDE_TEXT_HEIGHT}px;

  @media ${screen.lg} {
    padding: 30px 25px 25px;
  }
`

const Title = styled.h3`
  font-family: var(--font-heading);
  color: var(--heading);
  font-size: 1.75rem;
  margin-top: 0.33em;
  margin-bottom: 0.33em;
  line-height: 1.35;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media ${screen.lg} {
    margin-top: 1em;
  }
`

const Headline = styled.p`
  color: var(--text);
  line-height: 1.45;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 2em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  flex-grow: 1;
`

const Figure = styled.figure`
  position: relative;
  display: block;

  @media ${screen.lg} {
    flex: 0 0 66.67%;
    margin-bottom: 0;
  }
`

const ActionCall = styled.span`
  font-family: var(--font-heading);
  font-size: 1.25rem;
  font-weight: bold;
  text-decoration: none;
  color: var(--accent-bright);
  display: block;
`

const Pillar = styled(Tag)`
  margin-top: -15px;
  margin-bottom: 15px;

  @media ${screen.lg} {
    margin-top: 0;
    margin-bottom: 0;
  }
`

export default function Slide(props) {
  return (
    <StyledSlide style={props.style}>
      <LinkWrapper document={props.document} href={props.href} to={props.to}>
        <Figure>
          <Ratio />
          <Image src={withPrefix(props.src)} alt={props.alt} />
        </Figure>
        <TextGroup>
          <Pillar>News</Pillar>
          <Title>{props.title}</Title>
          <Headline>{props.description}</Headline>
          <ActionCall>Read more</ActionCall>
        </TextGroup>
      </LinkWrapper>
    </StyledSlide>
  )
}
