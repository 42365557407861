import React, { useState, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'

export const FilterButton = styled.div.attrs({ role: 'button' })`
  display: inline-block;
  font-size: 0.7rem;
  font-weight: 700;
  font-family: var(--font-text);
  line-height: 1.5em;
  text-transform: uppercase;
  color: var(--text);
  text-decoration: none;
  padding: 8px 20px;
  background-color: transparent;
  border: 2px solid var(--text);
  border-radius: 2px;
  letter-spacing: 0.1em;
  transition: color 150ms ease, background-color 150ms ease,
    transform 150ms ease;
  cursor: pointer;

  :hover {
    transform: translateY(-1px);
  }

  :active {
    color: var(--background);
    background-color: var(--text);
    transform: translateY(0px);
  }

  ${(props) =>
    props.active &&
    css`
      color: var(--background);
      background-color: var(--text);
    `}
`

export const FilterGroup = styled.div`
  & > * {
    margin: 0 10px 10px 0;
  }
`

// Custom hook that abstracts the logic for using radio toggles.
export function useRadioToggles(ids, onToggle) {
  const [activeId, setActiveId] = useState(null)

  // Wrapping onToggle in a ref allows the user of this hook to change the onToggle
  // without causing the toggleById functions to change.
  const onToggleRef = useRef()
  onToggleRef.current = onToggle

  // Data structure that makes sure the function's references doesn't change.
  // This improves performance because react bails rendering of a memoized component
  // (React.memo(component)) when none of the props/attributes' references have changed.
  const toggleById = useMemo(() => {
    let result = {}

    for (const id of ids) {
      result[id] = function performToggle() {
        // setState can be called with a function that receives as its argument
        // the current state. This approach prevents toggleFilterByFilterKey's
        // from changing thereby preventing its dependencies from changing their reference.
        setActiveId((currentActiveId) => {
          let newActiveId

          if (currentActiveId === id) {
            newActiveId = null
          } else {
            newActiveId = id
          }

          if (onToggleRef.current) {
            onToggleRef.current(newActiveId)
          }

          return newActiveId
        })
      }
    }

    return result
  }, [ids])

  return [activeId, toggleById]
}

// Handles the logic of toggling filters and renders the filter options
// Does NOT decide which items of the provided data should be filtered
// based on the current filter state. That is handled by the filterItem function
// passed by the user of this component.
export default function FilterData({
  // Instead of react elements this component expects a
  // function that gets the result of the data filtering.
  // This pattern is called render props, see https://reactjs.org/docs/render-props.html
  renderResult,
  // Object of which the keys are used for filtering and
  // values are used as labels for the filter buttons.
  filterOptions,
  // Function with arguments: (activeFilterKeys, element[, index[, array]])
  // This function is called for each item in data where
  // element will be the item the function is filtering.
  // If the filterItem returns true the item is kept and
  // when it returns false the item is filtered out.
  filterItem,
  // Array of data to be filtered.
  data,
  // when true, multiple filters can be active at a time.
  multiSelect,
  ...restProps
}) {
  // Keeps track of all the filter options that are activated by the user
  const [activeFilters, setActiveFilters] = useState([])

  function toggleFilter(filter) {
    return function performToggle() {
      if (activeFilters.includes(filter)) {
        setActiveFilters(activeFilters.filter((key) => key !== filter))
      } else {
        if (multiSelect) {
          setActiveFilters([...activeFilters, filter])
        } else {
          setActiveFilters([filter])
        }
      }
    }
  }

  let filteredData = data
  // When no filter is active show all data
  if (activeFilters.length >= 1) {
    filteredData = data.filter((...filterArgs) => {
      return filterItem(activeFilters, ...filterArgs)
    })
  }

  return (
    <>
      <FilterGroup {...restProps}>
        {Object.entries(filterOptions).map(([filterKey, filterName]) => (
          <FilterButton
            key={filterKey}
            onClick={toggleFilter(filterKey)}
            active={activeFilters.includes(filterKey)}
          >
            {filterName}
          </FilterButton>
        ))}
      </FilterGroup>
      {/*
      Calls the provided function with the filtered data.
      This function can then decide how the data items should be rendered.
      */}
      {renderResult(filteredData)}
    </>
  )
}
